import React, { useMemo } from "react";

import {
	prettyNumber,
	toProperCase,
	transformToCurrency,
	transformToPhone,
} from "@utils/transform";
import { LeadInfoCardTableRow } from "./Base";
import { useLeadState } from "@leads/context";
import { rowCardColumnsFixedLong } from "@leads/defaults";
import { GridItem } from "@leads/Grid";
import { Lead } from "@api/graphql/types";

interface Props {
	vehicle: NonNullable<NonNullable<Lead["tradeApps"]>["nodes"]>[0];
	loading: boolean;
	print: boolean;
}

export const TradeVehicles = () => {
	const {
		state: { lead, loading, error, print },
	} = useLeadState();

	const vehicles = useMemo(() => {
		return (lead?.tradeApps?.nodes || []) as NonNullable<
			NonNullable<Lead["tradeApps"]>["nodes"]
		>;
	}, [lead]);

	return (
		<>
			{vehicles.map((vehicle, i) => (
				<GridItem key={`vehicle-${i}`}>
					<TradeVehicle vehicle={vehicle} {...{ loading, print }} />
				</GridItem>
			))}
		</>
	);
};

export const TradeVehicle = ({ vehicle, loading, print }: Props) => {
	const data = {
		title: "Trade Vehicle",
		columns: rowCardColumnsFixedLong,
		selectors: [
			{
				property: "year",
				display: "Vehicle Year:",
			},
			{
				property: "make",
				display: "Vehicle Make:",
			},
			{
				property: "model",
				display: "Vehicle Model:",
			},
			{
				property: "trim",
				display: "Vehicle Trim:",
			},
			{
				property: "mileage",
				display: "Vehicle Mileage:",
				format: prettyNumber,
			},
			{
				property: "condition",
				display: "Vehicle Condition:",
				format: toProperCase,
			},
			{
				property: "minTradeInValue",
				display: "Rough Value:",
				format: transformToCurrency,
			},
			{
				property: "tradeInValue",
				display: "Average Trade In Value:",
				format: (val: number) => (val ? transformToCurrency(val) : ""),
			},
			{
				property: "maxTradeInValue",
				display: "Clean Value:",
				format: (val: number) => (val ? transformToCurrency(val) : ""),
			},
		],
		data: vehicle,
		loading,
		print,
		error: undefined,
	};

	return (
		<>
			<LeadInfoCardTableRow {...data} />
		</>
	);
};
