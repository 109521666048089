import React from "react";

import { useLeadActions } from "@api/leads";
import { useUserQuery } from "@api/users";

import { useFeedbackContext } from "~/components/Feedback";
import { Typography } from "~/components/Typography";

import { MenuItem, Select } from "@material-ui/core";

import { useLeadState } from "@leads/context";
import { LeadViewComponent } from "@leads/view";

import { LeadPriority, LeadState } from "@api/graphql/types";

import { customStyles } from "./styles";

import Skeleton from "@material-ui/lab/Skeleton";

const Selector = ({
	field,
	label,
	options,
}: {
	field: string;
	label: string;
	options: { label: string; value: string }[];
}) => {
	const {
		state: { lead },
	} = useLeadState();

	const { updateLeadField } = useLeadActions(lead.id);
	const { handleOpenFeedback } = useFeedbackContext();

	const styles = customStyles();

	const handleChange = async (
		event: React.ChangeEvent<{ value: unknown }>,
	) => {
		await updateLeadField(field, event.target.value as string);
		handleOpenFeedback({
			message: "Changes saved!",
			severity: "success",
		});
	};

	return (
		<div className={styles.selectOption}>
			<Typography variant="caption" className={styles.selectLabel}>
				{label}:
			</Typography>
			<Select
				id="select-lead-asignee"
				className={styles.select}
				value={(lead as any)?.[field]}
				onChange={handleChange}
				MenuProps={{
					getContentAnchorEl: () => null!,
				}}
			>
				{options.map(({ label, value }) => {
					return (
						<MenuItem key={`user-status-${value}`} value={value}>
							{label}
						</MenuItem>
					);
				})}
			</Select>
		</div>
	);
};

const DetailsForm: LeadViewComponent = () => {
	const {
		state: { lead },
	} = useLeadState();

	const { groups } = useUserQuery();

	const styles = customStyles();

	const formatLeadStatus = (state: LeadState) => {
		switch (state) {
			case LeadState.New:
				return "New";
			case LeadState.AppointmentBooked:
				return "Appointment booked";
			case LeadState.Contacted:
				return "Contacted";
			case LeadState.Dead:
				return "Dead";
			case LeadState.Sold:
				return "Sold";
			case LeadState.Working:
				return "Working";
			default:
				return "New";
		}
	};

	const formatLeadPriority = (priority: LeadPriority) => {
		switch (priority) {
			case LeadPriority.Low:
				return "Low";
			case LeadPriority.Medium:
				return "Medium";
			case LeadPriority.High:
				return "High";
			default:
				return "Medium";
		}
	};

	if (!lead || !groups) {
		return (
			<div className={styles.loading}>
				<Skeleton variant="rect" width="50%" />
				<Skeleton variant="rect" width="50%" />
				<Skeleton variant="rect" width="50%" />
			</div>
		);
	}

	const usersGroup = groups?.[0]?.users || [];

	return (
		<div className={styles.root}>
			<Selector
				field="assignee"
				label="Assignee"
				options={usersGroup?.map((user) => ({
					label: user.email,
					value: user.email,
				}))}
			/>
			<Selector
				field="state"
				label="Status"
				options={Object.values(LeadState).map((status) => ({
					label: formatLeadStatus(status),
					value: status,
				}))}
			/>
			<Selector
				field="priority"
				label="Priority"
				options={Object.values(LeadPriority).map((priority) => ({
					label: formatLeadPriority(priority),
					value: priority,
				}))}
			/>
		</div>
	);
};

export default DetailsForm;
