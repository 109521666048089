import React, { useMemo } from "react";

import { useLeadState } from "@leads/context";
import { LeadViewComponent } from "@leads/view";

import { Notification } from "./components/Notification";

import { Typography, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { Event, JourneyStage, LeadJourney } from "@api/graphql/types";
import { NotificationProps } from "./components/Notification/types";

export const useStyles = makeStyles(() => ({
	root: {
		padding: 16,
	},
	loading: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: 32,
		padding: 24,
		flexDirection: "column",
		height: "100%",
	},
	notifications: {
		display: "flex",
		flexDirection: "column",
		gap: 12,
	},
}));

const handledEvents = [
	"twilio.sendSms",
	"twilio.reply",
	"email.leadNotification",
	"email.adfNotification",
];
const formatEvent = (event: Event): NotificationProps | null => {
	const recipient = event?.meta?.to;
	switch (event.type) {
		case "twilio.sendSms":
			return {
				date: event.createdAt,
				message: "SMS Sent",
				content: event?.meta?.body,
			};
		case "twilio.reply":
			// twilio API uses uppercase in some fields. Do not modify, thank you.
			return {
				date: event.createdAt,
				message: "SMS Reply",
				content: event?.meta?.Body,
			};
		case "email.leadNotification":
			return {
				date: event.createdAt,
				message: "Email Sent",
				content: recipient
					? `An email notification was sent to email: ${recipient.join(", ")}`
					: `An email notification was sent.`,
			};
		case "email.adfNotification":
			return {
				date: event.createdAt,
				message: "ADF Sent",
				content: recipient
					? `An ADF notification was sent to email: ${recipient.join(", ")}`
					: `An ADF notification was sent.`,
			};
		default:
			return null;
	}
};

const handledLeadJourneys = [
	JourneyStage.IdComplete,
	JourneyStage.CreditScore,
	JourneyStage.IdLinkOpened,
	JourneyStage.DrivePartial,
	JourneyStage.Trade,
];
const formatLeadJourney = (event: LeadJourney): NotificationProps | null => {
	switch (event.stage) {
		case JourneyStage.IdComplete:
			return {
				date: event.createdAt,
				message: "AVA ID",
				content: "Driver's License submitted by customer",
			};
		case JourneyStage.CreditScore:
			return {
				date: event.createdAt,
				message: "AVA Credit",
				content: "Customer Received Credit Report",
			};
		case JourneyStage.IdSmsSent:
			return {
				date: event.createdAt,
				message: "AVA ID",
				content: "SMS Sent to Customer",
			};
		case JourneyStage.DrivePartial:
			return {
				date: event.createdAt,
				message: "AVA Drive",
				content: "Test Drive Appointment Booked by Customer",
			};
		case JourneyStage.Trade:
			return {
				date: event.createdAt,
				message: "AVA Trade",
				content: "Trade-in Inquiry submitted by Customer",
			};
		default:
			return null;
	}
};

const dedupe =
	(getter: (_: any) => string) => (value: any, index: number, self: any[]) =>
		index === self.findIndex((t) => getter(t) === getter(value));

const Activity: LeadViewComponent = () => {
	const {
		state: { lead },
	} = useLeadState();

	const styles = useStyles();

	const activity = useMemo(() => {
		const events =
			lead?.events?.nodes
				?.filter((event) => handledEvents.includes(event.type))
				.map(formatEvent)
				.filter(Boolean) || [];
		const leadJourneys =
			lead?.leadJourneys?.nodes
				?.filter((event) => handledLeadJourneys.includes(event.stage))
				// remove dupes
				.filter(dedupe((t) => t.stage))
				.map(formatLeadJourney)
				.filter(Boolean) || [];
		const activity: NotificationProps[] = [
			...events,
			...leadJourneys,
		] as NotificationProps[];
		activity.sort((a, b) => {
			if (a?.date < b?.date) {
				return -1;
			}

			if (a?.date > b?.date) {
				return 1;
			}

			return 0;
		});

		return activity;
	}, [lead]);

	const content = useMemo(() => {
		if (!lead) {
			return (
				<div className={styles.loading}>
					<Skeleton variant="rect" width="50%" />
					<Skeleton variant="rect" width="50%" />
					<Skeleton variant="rect" width="50%" />
				</div>
			);
		}

		if (!activity.length) {
			return <Typography variant="caption">No Activity Found</Typography>;
		}

		return (
			<div className={styles.notifications}>
				{activity.map((act, index) => {
					return <Notification key={index} {...act} />;
				})}
			</div>
		);
	}, [activity, lead]);

	return <div className={styles.root}>{content}</div>;
};

export default Activity;
