import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		gap: 4,
	},
	logo: {
		marginBottom: 8,
	},
	updatedAt: {
		fontSize: 12,
	},
	content: {
		padding: 8,
		backgroundColor: "#F8F8F8",
		borderRadius: 10,
		border: "1px solid #CACACA",
		wordBreak: "break-word",
	},
}));
