import React from "react";

import dayjs from "dayjs";

import { Typography } from "@material-ui/core";

import ACNotification from "~/assets/vectors/ac-notification-event.svg";

import { useStyles } from "./styles";

import { NotificationProps } from "./types";

export const Notification = (event: NotificationProps) => {
	const styles = useStyles();
	const updatedAt = dayjs(event.date).fromNow();

	return (
		<div className={styles.root}>
			<div className={styles.logo}>
				<ACNotification />
			</div>
			<div>
				<div className={styles.content}>{event.content}</div>
				<Typography className={styles.updatedAt} variant="body2">
					{updatedAt} • {event.message}
				</Typography>
			</div>
		</div>
	);
};
